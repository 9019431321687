import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { useRowGutterStyles } from '@mui-treasury/styles/gutter/row';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
    card: {
        minWidth: 130,
    },
    cardContent: {
        padding: 8,
    },
    cardMedia: {
        position: 'relative',
        aspectRatio: '3/4',
    },
}));


const ItemCardSkeleton: React.FC = () => {

    const theme = useTheme();
    const classes = useStyles();
    const gutterStyles = useRowGutterStyles({
        size: theme.spacing(1),
    });

    return (
        <Card className={classes.card} variant="outlined">
            <CardActionArea disableTouchRipple>
                <CardMedia className={classes.cardMedia}>
                    <Skeleton width="100%" height="100%" variant="rect" />
                </CardMedia>
                <CardContent className={classes.cardContent}>
                    <Skeleton width="100%">
                        <Typography variant="subtitle2">.</Typography>
                    </Skeleton>
                    <Skeleton width="100%">
                        <Typography variant="subtitle2">.</Typography>
                    </Skeleton>
                    <Box className={gutterStyles.parent}>
                        {[1, 2].map(_ => (
                            <Skeleton
                                width={10}
                                height={10}
                                style={{
                                    borderRadius: `${theme.shape.borderRadius}px`,
                                    display: 'inline-block',
                                }}
                                variant="rect"
                            />
                        ))}
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default ItemCardSkeleton;
