import {
    Button,
    Menu, MenuItem
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { translationServiceContext } from '../../../services/provider';
import { TranslationService } from '../../../services/translationService';
import { SortType } from '../../../state/globalState';
import SortIcon from '@material-ui/icons/Sort';

const useStyles = makeStyles((theme: Theme) => createStyles({

}));


const getSortTypeLabelKey = (sortType: SortType) => {
    if (SortType.DEFAULT === sortType) {
        return "SORT_RECOMMENDED";
    } else if (SortType.LOWEST_PRICE === sortType) {
        return "SORT_LOWEST_PRICE"
    } else {
        return "UNKNOWN";
    }
}

interface Props {
    readonly size?: 'small' | 'medium' | 'large';
    readonly label: string;
    readonly currentSortType: SortType;
    readonly onChange: (sortType: SortType) => void;
}

const SortButton: React.FC<Props> = ({ currentSortType, onChange, label, size = 'medium' }) => {

    const translationService: TranslationService = useContext<TranslationService>(translationServiceContext);

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                aria-controls="sort-menu"
                aria-haspopup="true"
                onClick={handleButtonClick}
                startIcon={<SortIcon />}
                variant="outlined"
                size={size}
            >
                {label}
            </Button>
            <Menu
                id="sort-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {[SortType.DEFAULT, SortType.LOWEST_PRICE].map((sortType) => (
                    <MenuItem
                        selected={sortType === currentSortType}
                        onClick={() => {
                            onChange(sortType);
                            handleClose();
                        }}>
                        {translationService.translate(getSortTypeLabelKey(sortType))}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default SortButton;
