import {
    Fab, useScrollTrigger, useTheme
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        position: 'fixed',
        right: theme.spacing(2),
    },
    whatsAppButton: {
        backgroundColor: '#128C7E',
        color: theme.palette.getContrastText('#128C7E'),
        '&:hover': {
            backgroundColor: '#0c6258',
        },
    }
}));

interface Props {
    readonly phoneNumber: string;
    readonly onClick: () => void;
}

const WhatsAppFAB: React.FC<Props> = ({ phoneNumber, onClick }) => {
    const classes = useStyles();
    const theme = useTheme();

    const trigger = useScrollTrigger();

    return (
        <Fab
            color="secondary"
            disableRipple
            disableFocusRipple
            size="small"
            className={clsx(classes.root, classes.whatsAppButton)}
            href={`https://wa.me/${phoneNumber}`}
            onClick={onClick}
            style={{
                bottom: theme.spacing(trigger ? 8 : 2),
            }}
        >
            <WhatsAppIcon />
        </Fab>
    );
};

export default WhatsAppFAB;
