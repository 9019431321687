import React from 'react';
import { useTheme } from '@material-ui/core/styles';

interface Props {
  color: string;
  current: boolean;
}

const ColorChip: React.FC<Props> = ({ color, current }) => {
  const theme = useTheme();

  // TODO: a specific CSS for the selected color

  return (
    <span
      style={{
        height: 10,
        width: 10,
        backgroundColor: color,
        borderRadius: `${theme.shape.borderRadius}px`,
        display: 'inline-block',
      }}
    />
  );
};

export default ColorChip;
