import React from 'react';
import {Button, Grid, Typography,} from '@material-ui/core';
import RemoveShoppingCartOutlinedIcon from '@material-ui/icons/RemoveShoppingCartOutlined';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

interface Props {
  readonly text: string;
  readonly buttonTitle: string;
  readonly buttonOnClick: () => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  containerPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  iconSize: {
    width: 120,
    height: 120,
  },
  titleContainer: {
    textAlign: 'center',
  },
}));

const NoFilterCategoryProducts: React.FC<Props> = ({buttonTitle, buttonOnClick, text}) => {

  // Styles
  const classes = useStyles();

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
      <Grid item>
        <RemoveShoppingCartOutlinedIcon fontSize="large" className={classes.iconSize} color="disabled" />
      </Grid>
      <Grid item className={classes.titleContainer}>
        <Typography variant="subtitle1">{text}</Typography>
      </Grid>
      <Grid item>
        <Button variant="contained" color="primary" onClick={buttonOnClick} disableElevation>
          {buttonTitle}
        </Button>
      </Grid>
    </Grid>
  );
};

export default NoFilterCategoryProducts;
