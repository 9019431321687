import React, {PropsWithChildren, useContext} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {navigate} from 'gatsby';
import {CardActionArea, Link} from 'gatsby-theme-material-ui';
import {createStyles, makeStyles, Theme, useTheme,} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import clsx from 'clsx';
import {useRowGutterStyles} from '@mui-treasury/styles/gutter/row';
import {GatsbyImage} from 'gatsby-plugin-image';
import ItemPrice from './itemPrice';
import {applyDiscount} from '../utils/general';
import {Discounted} from '../models/discounted';
import ColorChip from './colorChip';
import {Color, Colors} from '../services/colorsService';
import {TranslationService} from '../services/translationService';
import {translationServiceContext} from '../services/provider';
import {ImageModel, ImgixModel} from '../models/product';
import '../utils/extensions';
import ItemCardChip from "./itemCardChip";
import BlockIcon from '@material-ui/icons/Block';

const useStyles = makeStyles((theme: Theme) => createStyles({
  card: {
    minWidth: 130,
  },
  cardContent: {
    padding: 8,
  },
  cardMedia: {
    position: 'relative',
    aspectRatio: '3/4',
  },
  containObjectFit: {
    objectFit: 'contain',
  },
  squareChip: {
    borderRadius: 0,
    borderStyle: 'none',
  },
  itemNewStatus: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
  },
  hidden: {
    visibility: 'hidden',
  },
  outOfStock: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.background.paper,
  },
  itemDiscountRate: {
    position: 'absolute',
    left: theme.spacing(1),
    bottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    color: 'white',
  },
}));

interface Props {
  readonly slug: string;
  readonly title: string;
  readonly price: number;
  readonly currency: string;
  readonly isNew: boolean;
  readonly isOutOfStock: boolean;
  readonly image: ImageModel & ImgixModel;
  readonly colorId?: string;
  readonly discountedPrice?: number;
  readonly colors: ReadonlyArray<Color>;
  // TODO: specify type
  readonly surrounds: object;
  readonly onClick: () => void;
}

const ItemCard: React.FC<Props> = (props: PropsWithChildren<Props>) => {

  const translationService: TranslationService = useContext<TranslationService>(translationServiceContext);

  const classes = useStyles();
  const theme = useTheme();

  const gutterStyles = useRowGutterStyles({
    size: 4,
  });

  const {
    surrounds,
    price,
    discountedPrice,
    slug,
    title,
    image,
    isNew,
    isOutOfStock,
    currency,
    colors,
    colorId
  } = props;

  const compress = 0.2;

  const imageSizesAttribute: string = [
    // TODO: what about having a size bigger than the width of the image ?
    // because the parent container has a predefined maximum width `theme.breakpoints.values.lg`, we used here pixels instead of viewport
    `(min-width: ${theme.breakpoints.values.lg}px) ${((theme.breakpoints.values.lg / surrounds[theme.breakpoints.values.lg]) * (1 - compress)).toFixed(2)}px`,
    `(min-width: ${theme.breakpoints.values.md}px) ${((100 / surrounds[theme.breakpoints.values.lg]) * (1 - compress)).toFixed(2)}vw`,
    `(min-width: ${theme.breakpoints.values.sm}px) ${((100 / surrounds[theme.breakpoints.values.md]) * (1 - compress)).toFixed(2)}vw`,
    `${((100 / surrounds[theme.breakpoints.values.sm]) * (1 - compress)).toFixed(2)}vw`,
  ].join(', ');

  const appliedDiscount: Discounted | null = applyDiscount(price, discountedPrice);

  const itemCardLink: string = `${slug}/`;

  return (
    <Card className={classes.card} variant="outlined" component="article">
      <CardActionArea
        onClick={() => {
          // for SEO purposes, we don't want to wrap the whole card with an a href tag
          // we will only wrap the title of the card with a href
          props.onClick()
          navigate(itemCardLink);
        }}
        disableTouchRipple>
        <CardMedia title={title} className={classes.cardMedia}>
          <GatsbyImage
            image={image.imgixImage.gatsbyImageData}
            title={title}
            alt={title}
            itemProp="image"
            sizes={imageSizesAttribute}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            imgClassName={classes.containObjectFit}
          />
          {isOutOfStock && (
            <ItemCardChip
              label={translationService.translate('CATEGORY_OUT_OF_STOCK')}
              size="small"
              icon={<BlockIcon style={{color: 'white'}}/>}
              className={clsx(classes.outOfStock, classes.squareChip)}
            />
          )}
          {isNew && (
            <ItemCardChip
              label={translationService.translate('CATEGORY_NEW')}
              size="small"
              className={clsx(classes.itemNewStatus, classes.squareChip)}
            />
          )}
          {appliedDiscount && (
            <ItemCardChip
              label={`-${appliedDiscount.rate.roundAndMapToString(0)}%`}
              size="small"
              className={clsx(classes.itemDiscountRate, classes.squareChip)}
            />
          )}
        </CardMedia>
        <CardContent className={classes.cardContent}>
          <Typography variant="subtitle2" color="textPrimary" component="h2" noWrap>
            <Link to={itemCardLink} color="inherit">
              {title}
            </Link>
          </Typography>
          <ItemPrice
            initialPrice={appliedDiscount && price}
            currentPrice={appliedDiscount ? appliedDiscount.price : price}
            currency={currency}
          />
          <Box className={gutterStyles.parent}>
            {colors.length > 0 ? (
              colors.map((color: Color) => (
                <ColorChip key={color.id} color={color.hex} current={color.id === colorId}/>
              ))
            ) : (
              <ColorChip color={Colors.TRANSPARENT} current/>
            )}
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ItemCard;
