import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import {Button} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

interface Props {
  readonly title: string;
  readonly headerLeftButtonIcon: React.ReactElement;
  readonly headerLeftButtonOnClick: () => void;
  readonly headerRightButtonLabel?: string;
  readonly headerRightButtonDisabled?: boolean;
  readonly headerRightButtonOnClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }),
);

const DialogAppBar: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <AppBar className={classes.appBar} color="transparent">
      <Toolbar>
        <IconButton edge="start" color="inherit" onClick={props.headerLeftButtonOnClick}>
          {props.headerLeftButtonIcon}
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          {props.title}
        </Typography>
        {props.headerRightButtonLabel && props.headerRightButtonOnClick && (
          <Button color="inherit" disabled={props.headerRightButtonDisabled} onClick={props.headerRightButtonOnClick}>
            {props.headerRightButtonLabel}
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default DialogAppBar;
