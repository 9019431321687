import React, {useContext} from "react";
import DialogAppBar from "./dialogAppBar";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import {Box, Button, DialogActions, ListItemSecondaryAction, Typography} from "@material-ui/core";
import {FilterCriteria, FilterOptionWithClickEvent} from "./filterDialog";
import {TranslationService} from "../../../../services/translationService";
import {translationServiceContext} from "../../../../services/provider";
import {isTheSame} from "../../../../utils/operators";
import NoPaddingDialogContent from "./NoPaddingDialogContent";
import {IconButton} from "gatsby-theme-material-ui";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

interface Props {
  readonly onClose: () => void;
  readonly onClear: () => void;
  readonly onApply: () => void;
  readonly filterOptions: ReadonlyArray<FilterOptionWithClickEvent>;
  readonly selectedItems: ReadonlyArray<FilterCriteria>;
  readonly initialSelection: ReadonlyArray<FilterCriteria>;
}

const isTheSameFilters: (before: ReadonlyArray<FilterCriteria>, after: ReadonlyArray<FilterCriteria>) => boolean = (before, after) => {
  const beforeFlatten = before.flatMap(item => Array.from(item.items));
  const afterFlatten = after.flatMap(item => Array.from(item.items));
  return isTheSame(beforeFlatten, afterFlatten);
}

const FilterOptionsDialog: React.FC<Props> = (props) => {

  const translationService: TranslationService = useContext<TranslationService>(translationServiceContext);

  const isNoItemSelected: boolean = props.selectedItems.flatMap(item => Array.from(item.items)).length < 1;

  const nonEmptyFilterOptions = props.filterOptions.filter((filterOption: FilterOptionWithClickEvent) => filterOption.availableItems.size > 0);

  return (
    <>
      <DialogAppBar
        title={translationService.translate("FILTER_DIALOG_TITLE")}
        headerLeftButtonIcon={<CloseIcon/>}
        headerLeftButtonOnClick={props.onClose}
        headerRightButtonDisabled={isNoItemSelected}
        headerRightButtonLabel={translationService.translate("FILTER_DIALOG_CLEAR")}
        headerRightButtonOnClick={props.onClear}
      />
      <NoPaddingDialogContent dividers>
        <List>
          {nonEmptyFilterOptions.map((filterOption: FilterOptionWithClickEvent) => {
              const selected: FilterCriteria | undefined = props.selectedItems.find(item => filterOption.type === item.type);
              return (
                <React.Fragment key={filterOption.type}>
                  <ListItem button onClick={filterOption.onClick}>
                    <ListItemText
                      primary={(
                        <Typography variant="body1">
                            {filterOption.title}
                        </Typography>
                      )}
                      secondary={selected ? Array.from(selected.items)
                        .join(', ') : undefined}
                      secondaryTypographyProps={{
                        variant: 'subtitle1'
                      }}
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" onClick={filterOption.onClick}>
                        <ChevronRightIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider/>
                </React.Fragment>
              )
            }
          )}
        </List>
      </NoPaddingDialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          disabled={!isTheSameFilters(props.initialSelection, props.selectedItems)}
          fullWidth
          onClick={() => {
            props.onApply();
          }}
        >
          {translationService.translate("FILTER_DIALOG_APPLY")}
        </Button>
      </DialogActions>
    </>
  );
}

export default FilterOptionsDialog;
