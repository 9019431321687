import LinearProgress from '@material-ui/core/LinearProgress';
import { Theme } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';

const BorderLinearProgress = withStyles((theme: Theme) => ({
    root: {
        height: 3,
        borderRadius: theme.shape.borderRadius,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.primary.main,
    },
}))(LinearProgress);

export default BorderLinearProgress;