import { Box, useTheme } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Button as GatsbyButton } from 'gatsby-theme-material-ui';
import React from 'react';
import { CategoryBase, CategorySummary } from '../../../models/category';

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        webkitOverflowScrolling: 'touch',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        backgroundImage:
            `linear-gradient(to right, white, white),
            linear-gradient(to right, white, white),
            linear-gradient(to right, rgba(0 0 0 / 12%), rgba(255, 255, 255, 0)),
            linear-gradient(to left, rgba(0 0 0 / 12%), rgba(255, 255, 255, 0))`,
        backgroundPosition: `left center, right center, left center, right center`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'white',
        backgroundSize: '20px 100%, 20px 100%, 20px 100%, 20px 100%',
        backgroundAttachment: 'local, local, scroll, scroll'
    },
    unstyledUl: {
        padding: 0,
        margin: 0
    },
    unstyledLi: {
        listStyle: 'none'
    },
    boxGap: {
        '& > *:not(:first-child)': {
            marginRight: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),
        },
        '& > *:first-child': {
            marginRight: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),
        },
        '& > *:last-child': {
            marginRight: 0,
            marginBottom: theme.spacing(0.5),
        },
    },
    item: {
        flex: '0 0 auto'
    }
}));

interface Props {
    readonly category: CategorySummary;
}

const CategoryChildrenList: React.FC<Props> = ({ category }) => {

    const classes = useStyles();

    return (
        <Box component="ul" className={clsx(classes.container, classes.boxGap, classes.unstyledUl)}>
            {category.children.map((categoryChild: CategoryBase) => (
                <li className={clsx(classes.unstyledLi, classes.item)}>
                    <GatsbyButton
                        key={categoryChild.ref}
                        disableElevation
                        variant="outlined"
                        to={`/${category.slug}/${categoryChild.slug}/`}
                        size="small"
                    >
                        {categoryChild.title}
                    </GatsbyButton>
                </li>
            ))}
        </Box>
    );
};

export default CategoryChildrenList;
