import React, { useContext } from 'react';
import {
  Button, Grid, Typography,
} from '@material-ui/core';
import RemoveShoppingCartOutlinedIcon from '@material-ui/icons/RemoveShoppingCartOutlined';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Link from 'gatsby-link';
import { TranslationService } from '../../../services/translationService';
import { translationServiceContext } from '../../../services/provider';
import { NEW_CATEGORY_SLUG } from '../../../utils/constants';

const useStyles = makeStyles((theme: Theme) => createStyles({
  containerPadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  iconSize: {
    width: 120,
    height: 120,
  },
  titleContainer: {
    textAlign: 'center',
  },
}));

const NoItem: React.FC = () => {
  // Inject required services
  const translationService: TranslationService = useContext<TranslationService>(translationServiceContext);

  // Styles
  const classes = useStyles();

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
      <Grid item>
        <RemoveShoppingCartOutlinedIcon fontSize="large" className={classes.iconSize} color="disabled" />
      </Grid>
      <Grid item className={classes.titleContainer}>
        <Typography variant="subtitle1">{translationService.translate('CATEGORY_NO_PRODUCT_AVAILABLE_FOR_THIS_CATEGORY')}</Typography>
      </Grid>
      <Grid item>
        <Button component={Link} to={`/${NEW_CATEGORY_SLUG}/`} variant="contained" color="primary" disableElevation>
          {translationService.translate('CATEGORY_SEE_OUR_NEW_PRODUCTS')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default NoItem;
