import { Badge, Box, Button, Container, Fab, Grid, Theme, Typography, useTheme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TuneIcon from '@material-ui/icons/Tune';
import { Skeleton } from '@material-ui/lab';
import { useRowGutterStyles } from "@mui-treasury/styles/gutter/row";
import clsx from 'clsx';
import { IGatsbyImageData, getSrc } from "gatsby-plugin-image";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ArrayParam, useQueryParams, withDefault } from "use-query-params";
import { BreadcrumbEntry } from "../../models/breadcrumbEntry";
import { CategorySummary } from '../../models/category';
import { ProductSummary } from '../../models/product';
import { ColorsService } from '../../services/colorsService';
import { colorServiceContext, eventsServiceContext, translationServiceContext } from '../../services/provider';
import { TranslationService } from '../../services/translationService';
import ItemCard from '../../shared-components/itemCard';
import ItemCardSkeleton from '../../shared-components/itemCardSkeleton';
import Meta from '../../shared-components/meta';
import NoFilterCategoryProducts from "../../shared-components/noFilterCategoryProducts";
import Perks from '../../shared-components/perks';
import PreBreadcrumbs from '../../shared-components/preBreadcrumbs';
import ScrollTop from './components/scrollTop';
import { CategoryPageState, FilterSearchCriteria, SortType } from '../../state/globalState';
import { NUMBER_OF_ITEMS_PER_PAGE } from '../../utils/constants';
import { range, requireNonNull } from '../../utils/operators';
import BorderLinearProgress from './components/borderLinearProgress';
import CategoryChildrenList from './components/categoryChildrenList';
import FilterDialog, { FilterCriteria, FilterType } from "./components/filter/filterDialog";
import NoItem from './components/noItem';
import SortButton from './components/sortButton';
import { CategoryFilters } from "./paginatedPageTemplateContext";
import WhatsAppFAB from './components/whatsappFAB';
import { EventsService } from '../../services/eventsService';

const useStyles = makeStyles((theme: Theme) => createStyles({
  breadcrumbPaper: {
    marginBottom: theme.spacing(2),
  },
  breadcrumbPaperSm: {
    margin: `0px ${theme.spacing(1)}px ${theme.spacing(1)}px 0px`,
  },
  itemsHeader: {
    paddingBottom: theme.spacing(2),
  },
  containerPaddingTop: {
    paddingTop: theme.spacing(2),
  },
  containerPaddingTopSm: {
    paddingTop: theme.spacing(1),
  },
  smContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  container: {
    paddingBottom: theme.spacing(3),
  },
  fullWidth: {
    width: '100%',
  },
  zeroMargin: {
    margin: 0
  },
  unstyledUl: {
    padding: 0
  },
  unstyledLi: {
    listStyle: 'none'
  }
}));

interface Props {
  readonly data: {
    readonly products: ReadonlyArray<ProductSummary>;
    readonly title: string;
    readonly category: CategorySummary;
    readonly totalProductsCount: number;
    readonly currency: string;
    readonly categoryFilters: CategoryFilters;
  };
  readonly globalState: CategoryPageState;
}

const getProductPrice = (product: ProductSummary): number => {
  return product.discountedPrice > 0 ? product.discountedPrice : product.sellingPrice;
}

// first element represents the total number of products displayed on the page
const getProductsToDisplay = (categoryCursor: number, allProducts: ReadonlyArray<ProductSummary> | undefined, sort: SortType = SortType.DEFAULT, filter: FilterSearchCriteria, totalProductsCount: number): [number, ReadonlyArray<ProductSummary>] => {
  let products: ReadonlyArray<ProductSummary> = (allProducts != null && true) ? allProducts : [];
  const productsLength: number = products.length;
  // do filter only if filter criteria has at least one element
  if (filter.categories.size >= 1 || filter.sizes.size >= 1) {
    products = products.filter(product => {
      return (filter.categories.size > 0 ? (filter.categories.has(product.category.slug)) : true)
        && (filter.sizes.size > 0
          ? product.quantityPerSize
            .filter(qps => qps.quantity > 0)
            .flatMap(qps => qps.size)
            .some(element => filter.sizes.has(element))
          : true);
    });
  }

  if (products.length > 0) {
    let sortedProducts;
    switch (sort) {
      case SortType.LOWEST_PRICE:
        sortedProducts = [...products].sort((p1, p2) => {
          return getProductPrice(p1) - getProductPrice(p2);
        });
        break;
      case SortType.DEFAULT:
        sortedProducts = products;
        break;
      default:
        sortedProducts = products;
    }
    const totalProducts = (sortedProducts.length < productsLength) ? sortedProducts.length : totalProductsCount;
    sortedProducts = sortedProducts.slice(0, categoryCursor * NUMBER_OF_ITEMS_PER_PAGE);

    return [totalProducts, sortedProducts];
  } else {
    return [0, []];
  }
}

const getProductsToDisplayFromGlobalState = (globalState: CategoryPageState, totalProductsCount: number): [number, ReadonlyArray<ProductSummary>] => {
  return getProductsToDisplay(
    globalState.categoryCursor,
    globalState.categoriesItems.get(requireNonNull(globalState.categoryKey))?.products,
    globalState.categorySort,
    globalState.categoryFilter,
    totalProductsCount
  );
};

const WArrayParam = withDefault(ArrayParam, []);

const Category: React.FC<Props> = (props) => {

  const colorService: ColorsService = useContext<ColorsService>(colorServiceContext);
  const translationService: TranslationService = useContext<TranslationService>(translationServiceContext);
  const eventsService: EventsService = useContext<EventsService>(eventsServiceContext);

  const {
    globalState,
    data: {
      title,
      products,
      category,
      totalProductsCount,
      currency,
      categoryFilters
    },
  } = props;

  // charging the category page for the first time, or switching to another category page
  const [initialLoad, setInitialLoad] = useState<boolean>(!globalState.categoryKey || (globalState.categoryKey != category.slug));
  const [loading, setLoading] = React.useState<boolean>(true);
  const [loadingMore, setLoadingMore] = React.useState<boolean>(false);
  const [productsDataDetails, setProductsDataDetails] = useState<[number, ReadonlyArray<ProductSummary>]>([totalProductsCount, []]);

  // https://github.com/mui/material-ui/issues/16325#issuecomment-905876236
  const [openFilterDialog, setOpenFilterDialog] = useState<boolean>(false);
  const openFilterDialogId = useRef<number>(1);
  useEffect(() => {
    if (!openFilterDialog) { // Increment id each time modal closes
      openFilterDialogId.current = openFilterDialogId.current + 1;
    }
  }, [openFilterDialog, globalState.categoryFilter]);

  const [queryParam, setQueryParam] = useQueryParams({
    tailles: WArrayParam,
    categories: WArrayParam
  });

  const queryParamsExists: () => boolean = () => {
    return queryParam && ((queryParam.tailles && queryParam.tailles.length > 0) || (queryParam.categories && queryParam.categories.length > 0));
  };

  const getFilterSearchCriteriaFromQueryParams: () => FilterSearchCriteria = () => {
    return {
      sizes: queryParam.tailles ? new Set<string>(queryParam.tailles.filter(size => size !== null)) : new Set(),
      categories: queryParam.categories ? new Set<string>(queryParam.categories.filter(category => category !== null)) : new Set()
    } as FilterSearchCriteria
  };

  const isFilterEmpty: boolean = globalState.categoryFilter.categories.size == 0 && globalState.categoryFilter.sizes.size == 0;

  useEffect(() => {
    if (globalState.categoryKey) {
      setLoading(true);
    }
  }, [globalState.categorySort, globalState.categoryFilter]);

  useEffect(() => {
    if (initialLoad) {
      setLoading(true);
    }
  }, [initialLoad]);

  useEffect(() => {
    const handleScroll: () => void = () => {
      if (globalState.scrollYPosition) {
        window.scrollTo(0, globalState.scrollYPosition);
        globalState.setScrollYPosition(null);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [globalState.scrollYPosition, loadingMore, loading]);

  // initial load (see default value for the initialLoad local state)
  useEffect(() => {
    if (initialLoad) {
      if (queryParamsExists()) {
        globalState.handleCategoryChangeBasedOnQueryParams(category.slug, getFilterSearchCriteriaFromQueryParams(), totalProductsCount)
          .then(() => {
            setInitialLoad(false);
          })
      } else {
        globalState.handleCategoryChange(category.slug, products, totalProductsCount);
        setInitialLoad(false);
      }
    }
  }, [initialLoad]);

  useEffect(() => {
    if (globalState.categoryKey && loading) {
      let productsToDisplay: [number, ReadonlyArray<ProductSummary>];
      if (initialLoad) {
        productsToDisplay = [totalNumberOfProducts, products];
        setInitialLoad(false);
      } else {
        productsToDisplay = getProductsToDisplayFromGlobalState(globalState, totalProductsCount);
      }
      setProductsDataDetails(productsToDisplay);
      setLoading(false);
    }
  }, [loading, initialLoad]);

  // handling load more event (incrementing category cursor)
  useEffect(() => {
    if (globalState.categoryKey) {
      const productsToDisplay: [number, ReadonlyArray<ProductSummary>] = getProductsToDisplayFromGlobalState(globalState, totalProductsCount);
      setProductsDataDetails(productsToDisplay);
      setLoadingMore(false);
    }
  }, [globalState.categoryCursor]);

  const productsData = productsDataDetails[1];

  const totalNumberOfProducts = productsDataDetails[0];

  const onFilterChange = (filters: ReadonlyArray<FilterCriteria>) => {
    const filterSearchCriteria: FilterSearchCriteria = {
      categories: filters.find(item => FilterType.CATEGORIES === item.type)?.items ?? new Set(),
      sizes: filters.find(item => FilterType.SIZES === item.type)?.items ?? new Set()
    };

    globalState.updateCategoryFilter(filterSearchCriteria);
    setOpenFilterDialog(false);

    setQueryParam({
      tailles: filterSearchCriteria.sizes.size > 0 ? Array.from(filterSearchCriteria.sizes) : undefined,
      categories: filterSearchCriteria.categories.size > 0 ? Array.from(filterSearchCriteria.categories) : undefined
    });
  }

  const theme: Theme = useTheme();
  const classes = useStyles();

  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  const breakpoints = {
    [theme.breakpoints.values.lg]: 4,
    [theme.breakpoints.values.md]: 3,
    [theme.breakpoints.values.sm]: 2,
  };

  // At build time, window is undefined (we are not at a browser)
  const pageCanonicalURL = `${process.env.GATSBY_APP_URL}/${category.slug}/`;

  // SEO page title
  const pageTitle: string = [category.seoTitle, category.shortDescription, props.data.title]
    .filter((s: string | undefined) => typeof s !== 'undefined' && s !== null && s.length > 0)
    .join(' | ');

  const firstProductImage: IGatsbyImageData | undefined = products[0]?.featuredImage?.imgixImage?.gatsbyImageData;
  // Use first product image as category image, if not logo
  const seoCategoryImageUrl: string = getSrc(firstProductImage) ?? `${process.env.GATSBY_APP_URL}/images/logo-graphic.jpg`

  const breadcrumbsItems: ReadonlyArray<BreadcrumbEntry> = [
    {
      link: '/',
      title: translationService.translate('BREADCRUMB_HOME')
    },
    ...category.parents.map((cat, index) => {
      return {
        link: `/${category.parents.slice(0, index + 1)
          .map(it => it.slug)
          .join("/")}/`,
        title: cat.title
      }
    }),
    {
      title: category.title
    }
  ];

  const gutterStyles = useRowGutterStyles({
    size: theme.spacing(1),
  });

  // check whether this category contains a products (without a filter)
  const stateCategoryItemsLength: number | undefined = globalState.categoryKey ? globalState.categoriesItems.get(globalState.categoryKey)?.products?.length ?? undefined : undefined;

  return (
    <>
      <Meta
        siteTitle={title}
        title={pageTitle}
        imageSrc={seoCategoryImageUrl}
        description={category.description}
        url={pageCanonicalURL}
      />
      <Container
        className={clsx(classes.container, isDownSm ? classes.containerPaddingTopSm : classes.containerPaddingTop, isDownSm ? classes.smContainer : undefined)}
        disableGutters={isDownSm}
      >
        <div id="back-to-top-anchor" />
        <Box className={isDownSm ? classes.breadcrumbPaperSm : classes.breadcrumbPaper}>
          <PreBreadcrumbs isDownSm={isDownSm} items={breadcrumbsItems} />
        </Box>
        <Grid
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          container
          className={classes.itemsHeader}
        >
          <Grid item container xs sm={9} md={7} spacing={1} direction="column">
            <Grid item>
              <Typography variant="h5" component="h1">
                {category.title.toLocaleUpperCase()}
              </Typography>
            </Grid>
            {category.children.length > 0 && (
              <Grid item>
                <CategoryChildrenList category={category} />
              </Grid>
            )}
            {category.description && (
              <Grid item>
                <Typography variant="body2" component="p" gutterBottom>
                  {category.description}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid item className={classes.fullWidth} alignItems="center" justifyContent="center">
            {products.length > 0 && (
              <>
                <FilterDialog
                  key={openFilterDialogId.current}
                  open={openFilterDialog}
                  onClose={() => {
                    setOpenFilterDialog(false);
                  }}
                  filterOptions={[
                    {
                      type: FilterType.SIZES,
                      title: translationService.translate("FILTER_SIZES_TITLE"),
                      availableItems: new Set(categoryFilters.sizes.flatMap(v => v.sizes))
                    },
                    {
                      type: FilterType.CATEGORIES,
                      title: translationService.translate("FILTER_CATEGORIES_TITLE"),
                      availableItems: new Set(categoryFilters.categories)
                    }
                  ]}
                  initialSelection={[
                    {
                      type: FilterType.SIZES,
                      items: globalState.categoryFilter.sizes
                    },
                    {
                      type: FilterType.CATEGORIES,
                      items: globalState.categoryFilter.categories
                    }
                  ]}
                  onChange={onFilterChange}
                />
                <Grid container item justifyContent="space-between" direction="row" alignItems="center">
                  <Grid item>
                    <Box className={gutterStyles.parent}>
                      <Badge
                        color="primary"
                        badgeContent={globalState.categoryFilter.categories.size + globalState.categoryFilter.sizes.size}
                        onClick={() => {
                          setOpenFilterDialog(true);
                        }}>
                        <Button
                          color="default"
                          variant="outlined"
                          size="small"
                          startIcon={<TuneIcon />}
                        >
                          {translationService.translate("FILTER")}
                        </Button>
                      </Badge>
                      {loading ? (
                        <Skeleton style={{ display: 'inline' }} width="100%">
                          <Typography variant="subtitle2" color="textSecondary" display="inline">
                            {`-- ${translationService.translate('CATEGORY_PRODUCTS')}`}
                          </Typography>
                        </Skeleton>
                      ) : (
                        <Typography variant="subtitle2" color="textSecondary" display="inline">
                          {loading ? <Skeleton /> : `${totalNumberOfProducts} ${translationService.translate('CATEGORY_PRODUCTS')}`}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item>
                    <SortButton
                      label={translationService.translate("SORT")}
                      size="small"
                      currentSortType={globalState.categorySort}
                      onChange={(sortType) => globalState.setCategorySort(sortType)}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {/* fix grids, find an elegant way */}
            <Grid item>
              <br />
            </Grid>
            <Grid item container>
              {/* todo add an icon to present that there is no item based on the given filters */}
              {!loading ? (
                (initialLoad ? products.length > 0 : (stateCategoryItemsLength && stateCategoryItemsLength > 0)) ? (
                  (productsData.length > 0 || isFilterEmpty) ? (
                    <>
                      <Grid item container spacing={1} component="ul" className={classes.unstyledUl}>
                        {productsData.map((item: ProductSummary) => (
                          <Grid item xs={6} sm={4} md={3} component="li" className={classes.unstyledLi}>
                            <ItemCard
                              key={item.slug}
                              slug={`/${item.slug}`}
                              title={item.title}
                              currency={currency}
                              price={item.sellingPrice}
                              discountedPrice={item.discountedPrice}
                              isNew={item.new}
                              isOutOfStock={item.totalQuantity < 1}
                              image={item.featuredImage}
                              colorId={item.colorId}
                              colors={colorService.findEntities({
                                identifiers: item.colorIds,
                              })}
                              surrounds={breakpoints}
                              onClick={() => {
                                globalState.setScrollYPosition(window.scrollY)
                              }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                      {(productsData.length < totalNumberOfProducts) && productsData.length > 0 && (
                        <Grid
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          spacing={2}
                          style={{
                            marginTop: 8,
                            marginBottom: 8
                          }}
                        >
                          <Grid item>
                            {`Vous avez vu ${productsData.length} articles sur ${totalNumberOfProducts}`}
                          </Grid>
                          <Grid item style={{ width: '50%' }}>
                            <BorderLinearProgress
                              title={`${productsData.length}/${totalNumberOfProducts}`}
                              color="primary"
                              variant="determinate"
                              value={(productsData.length / totalNumberOfProducts) * 100}
                            />
                          </Grid>
                          <Grid item>
                            <Button
                              color="primary"
                              variant="contained"
                              disableElevation
                              size="large"
                              onClick={() => {
                                setLoadingMore(true);
                                globalState.loadMore();
                              }}
                              disabled={loadingMore}
                            >
                              {loadingMore
                                ? translationService.translate('CATEGORY_LOADING_MORE_PRODUCTS')
                                : translationService.translate('CATEGORY_LOAD_MORE_PRODUCTS')}
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  ) : (
                    <NoFilterCategoryProducts
                      text={translationService.translate("NO_FILTER_ITEMS_FOUND")}
                      buttonTitle={translationService.translate("RESET_FILTER")}
                      buttonOnClick={globalState.resetCategoryFilters}
                    />
                  )
                ) : (
                  <NoItem />
                )
              ) : (
                <Grid item container spacing={1} component="ul" className={classes.unstyledUl}>
                  {range(0, NUMBER_OF_ITEMS_PER_PAGE).map(_ =>
                    <Grid item xs={6} sm={4} md={3} component="li" className={classes.unstyledLi}>
                      <ItemCardSkeleton />
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item className={classes.fullWidth}>
            <Perks asPaper />
          </Grid>
        </Grid>
        <WhatsAppFAB
          phoneNumber="212540024047"
          onClick={eventsService.handleSocialLink}
        />
        <ScrollTop {...props} elementId="back-to-top-anchor">
          <Fab color="secondary" size="small">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
      </Container>
    </>
  );
};

export default Category;
