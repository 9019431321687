import {Chip, withStyles} from "@material-ui/core";

const ItemCardChip = withStyles({
  root: {
    fontSize: '0.75rem'
  },
  sizeSmall: {
    height: 16
  },
  labelSmall: {
    paddingLeft: 5,
    paddingRight: 5
  }
})(Chip);

export default ItemCardChip;