import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  initialPrice: {
    textDecoration: 'line-through',
  },
}));

interface Props {
  readonly currency: string;
  readonly currentPrice: number;
  readonly initialPrice: number | null;
}

const ItemPrice: React.FC<Props> = ({ currency, currentPrice, initialPrice }) => {
  const classes = useStyles();

  return (
    <Grid container direction="row" spacing={1}>
      {initialPrice && (
      <Grid item>
        <Typography variant="subtitle2" color="textSecondary" component="p" className={classes.initialPrice}>
          {`${initialPrice} ${currency}`}
        </Typography>
      </Grid>
      )}
      <Grid item>
        <Typography variant="subtitle2" color="primary" component="p">
          {`${currentPrice} ${currency}`}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ItemPrice;
