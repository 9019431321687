import {DialogContent, withStyles} from "@material-ui/core";

const NoPaddingDialogContent = withStyles({
  root: {
    padding: 0
  },
  dividers: {
    padding: 0
  }
})(DialogContent);

export default NoPaddingDialogContent;