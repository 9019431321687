import React, {useContext, useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import {TranslationService} from "../../../../services/translationService";
import {translationServiceContext} from "../../../../services/provider";
import {Button, DialogActions, useTheme} from "@material-ui/core";
import {useScreen} from "@mui-treasury/layout";
import {Breakpoint} from "@material-ui/core/styles/createBreakpoints";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DialogAppBar from "./dialogAppBar";
import FilterOptionsDialog from "./filterOptionsDialog";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {isTheSame} from "../../../../utils/operators";
import NoPaddingDialogContent from "./NoPaddingDialogContent";

export interface FilterOption {
  readonly type: FilterType;
  readonly title: string;
  readonly availableItems: ReadonlySet<string>;
}

export interface FilterOptionWithClickEvent extends FilterOption {
  readonly onClick: () => void;
}

export enum FilterType {
  SIZES,
  CATEGORIES
}

export interface FilterCriteria {
  readonly type: FilterType;
  readonly items: ReadonlySet<string>;
}

interface Props {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly filterOptions: ReadonlyArray<FilterOption>;
  readonly onChange: (filters: ReadonlyArray<FilterCriteria>) => void;
  readonly initialSelection: ReadonlyArray<FilterCriteria>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullWidth: {
      width: '100%',
    },
  }),
);

const FilterDialog: React.FC<Props> = ({filterOptions, open, onClose, onChange, initialSelection}) => {
  // Inject required services
  const translationService: TranslationService = useContext<TranslationService>(translationServiceContext);

  const theme = useTheme();
  const classes = useStyles();

  const screen: Breakpoint = useScreen();
  const fullScreen = screen === 'xs' || screen === 'sm';

  const [globalSelection, setGlobalSelection] = useState<ReadonlyArray<FilterCriteria>>(initialSelection);

  const [currentDialog, setCurrentDialog] = useState<string | FilterType>("main");

  // contains available items
  const currentFilterOption: FilterOption | undefined = filterOptions.find((filterOption: FilterOption) => currentDialog === filterOption.type);

  const getCurrentFilterOptionSelectedItems = currentFilterOption !== undefined ?
    {
      type: currentFilterOption.type,
      items: globalSelection.find(option => option.type === currentFilterOption.type)?.items ?? new Set()
    } as FilterCriteria
    : undefined

  // contains selected items
  const [currentFilterOptionSelectedItems, setCurrentFilterOptionSelectedItems] = useState<FilterCriteria | undefined>(undefined);

  const isCurrentFilterCriteriaDidNotChangedComparedToPreselectedItems = currentFilterOptionSelectedItems && currentFilterOption
    && isTheSame<string>(
      Array.from(globalSelection.find(option => option.type === currentFilterOption.type)?.items ?? []),
      Array.from(currentFilterOptionSelectedItems.items)
    );

  const resetGlobalSelection: () => void = () => {
    setGlobalSelection([
      {
        type: FilterType.SIZES,
        items: new Set()
      },
      {
        type: FilterType.CATEGORIES,
        items: new Set()
      }
    ])
  };

  const isMainDialog = currentDialog && currentDialog === "main";

  const switchToMainDialog: () => void = () => {
    setCurrentDialog("main");

  };

  const switchToDialog: (dialogName: FilterType) => void = (dialogName) => {
    setCurrentDialog(dialogName);
  };

  useEffect(() => {
    setCurrentFilterOptionSelectedItems(getCurrentFilterOptionSelectedItems);
  }, [currentDialog])

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={onClose} scroll="paper" fullWidth maxWidth="sm">
      <>
        {isMainDialog ? (
          <FilterOptionsDialog
            initialSelection={initialSelection}
            onClose={onClose}
            onClear={resetGlobalSelection}
            filterOptions={filterOptions.map(filterOption => ({
              ...filterOption,
              onClick: () => {
                switchToDialog(filterOption.type);
              }
            }))}
            selectedItems={globalSelection}
            onApply={() => {
              onChange(globalSelection);
            }}
          />
        ) : undefined}
        {currentFilterOption && currentFilterOptionSelectedItems && (
          <>
            <DialogAppBar
              title={currentFilterOption.title}
              headerLeftButtonIcon={<ArrowBackIosIcon/>}
              headerLeftButtonOnClick={switchToMainDialog}
            />
            <NoPaddingDialogContent dividers>
              <FormGroup>
                <List dense className={classes.fullWidth}>
                  {Array.from(currentFilterOption.availableItems).map((item: string) => (
                    <ListItem
                      key={item}
                      dense
                      button
                      onClick={() => {
                        if (currentFilterOptionSelectedItems.items.has(item)) {
                          setCurrentFilterOptionSelectedItems({
                            type: currentFilterOptionSelectedItems.type,
                            items: new Set(Array.from(currentFilterOptionSelectedItems.items).filter(i => i !== item))
                          });
                        } else {
                          setCurrentFilterOptionSelectedItems({
                            type: currentFilterOptionSelectedItems.type,
                            items: new Set([...currentFilterOptionSelectedItems.items, item])
                          });
                        }
                      }}
                    >
                      <FormControlLabel
                        label={item}
                        control={<Checkbox color="primary" name={item}
                                           checked={currentFilterOptionSelectedItems.items.has(item)}/>}
                      />
                    </ListItem>
                  ))}
                </List>
              </FormGroup>
            </NoPaddingDialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                disabled={!isCurrentFilterCriteriaDidNotChangedComparedToPreselectedItems}
                onClick={() => {
                  setGlobalSelection([
                    ...globalSelection.filter(opt => opt.type != currentFilterOption.type),
                    currentFilterOptionSelectedItems
                  ]);
                  switchToMainDialog();
                }}
              >
                {translationService.translate("FILTER_DIALOG_SAVE")}
              </Button>
            </DialogActions>
          </>

        )
        }
      </>
    </Dialog>
  );
};

export default FilterDialog;
