import React from 'react';
import {graphql, PageProps} from 'gatsby';
import {CategoryPageState, GlobalStateContext} from '../../state/globalState';
import Category from './category';
import {PaginatedPageTemplateContext} from './paginatedPageTemplateContext';

interface Props {
  readonly general: {
    readonly frontmatter: {
      readonly title: string;
    };
  };
  readonly settings: {
    readonly currencySymbol: string;
  };
}

const PaginatedPageTemplate: React.FC<PageProps<Props, PaginatedPageTemplateContext>> = ({ data, pageContext }) => (
  <GlobalStateContext.Consumer>
    {(globalState: CategoryPageState) => (
      <Category
        globalState={globalState}
        data={{
          title: data.general.frontmatter.title,
          currency: data.settings.currencySymbol,
          category: pageContext.category,
          products: pageContext.products,
          totalProductsCount: pageContext.totalProductsCount,
          categoryFilters: pageContext.categoryFilters
        }}
      />
    )}
  </GlobalStateContext.Consumer>
);

export default PaginatedPageTemplate;

export const categoryPageQuery = graphql`
  query GetCategoryPageData {
    general: markdownRemark(fileAbsolutePath: { regex: "/settings/general.md$/" }) {
      frontmatter {
        title
      }
    }
    settings: settings {
      currencySymbol
    }
  }
`;
